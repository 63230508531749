import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class PatienttreatmentService {

  constructor(private http: HttpClient) { }

  savePatienttreatment(model: any) {
    return this.http.post(environment.apiUrl + 'PatientTreatment/Save', model);
  }
  GetPatientTreatmentByIdPatientVisit(id) {
    return this.http.get(environment.apiUrl + 'PatientTreatment/GetPatientTreatmentByIdPatientVisit?id=' + id);
  }
  DeleteByIdPatientVisit(id) {
    return this.http.delete(environment.apiUrl + 'PatientTreatment/DeleteByIdPatientVisit?id=' + id);
  }
  updarePatienttreatment(model: any) {
    return this.http.patch(environment.apiUrl + 'PatientTreatment/Update', model);
  }
}
