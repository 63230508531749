import { Injectable } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatientAppointmentService {
public rows:any=[];

  constructor(private http: HttpClient) { }
  updateAppointmenty(model: any) {
    return this.http.patch(environment.apiUrl + 'PatientAppointment/Update', model);

  }

  GetPatientAppointmentById(id): Observable<any> {
    return this.http.get(environment.apiUrl + 'PatientAppointment/GetPatientAppointmentById?id=' + id);
  }

  saveAppointment(model: any) {
    return this.http.post(environment.apiUrl + 'PatientAppointment/Save', model);
  }
  GetPatientAppointmentByIdPatient(id): Observable<any> {
    return this.http.get(environment.apiUrl + 'PatientAppointment/GetPatientAppointmentByIdPatient?id=' + id);

  }
  AllAppointment(): Observable<any> {
    return this.http.get(environment.apiUrl + 'PatientAppointment/GetAllPatientAppointment');
  }
  GetTodaysPatientAppointment() {
    return this.http.get(environment.apiUrl + 'PatientAppointment/GetTodaysPatientAppointment');
  }
  delete(id) {
    return this.http.delete(environment.apiUrl + 'PatientAppointment/Delete?id=' + id);
  }
}
