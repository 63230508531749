import { Injectable } from '@angular/core';
import{ Employeee } from 'src/app/Entites/employee/employee.module';
import { NgForm } from '@angular/forms';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  
  constructor(private http: HttpClient) { }
  saveuser(model: any)
  {
    return this.http.post(environment.apiUrl + 'User/Save',model);
  }
  GetAllUsers(): Observable<any> {
    return this.http.get(environment.apiUrl + 'User/GetAlluser');
  }
  delete(id){
    return this.http.delete(environment.apiUrl + 'User/Delete?id='+id);
   }
   updateuser(model:any){
    return this.http.patch(environment.apiUrl + 'User/Update',model);
   }
   UpdateUserProfile(model:any){
    return this.http.patch(environment.apiUrl + 'User/UpdateProfile',model);
   }
   UpdateUserPass(model:any){
    return this.http.patch(environment.apiUrl + 'User/UpdatePass',model);
   }
   GetUserById(id): Observable<any>{
    return this.http.get(environment.apiUrl + 'User/GetUserById?id='+id);
   }
}
